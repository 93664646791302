import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import usersData from '../data/users.json';
import NotFound from './NotFound';
import './UserProfile.css';
import * as io5 from 'react-icons/io5';
import * as md from 'react-icons/md';
import qrtacLogo from '../assets/logo-white.svg';
import Modal from 'react-modal'; // Add Modal import
import QRCode from 'qrcode.react'; // Add QRCode import

function UserProfile() {
  const { username } = useParams();
  const location = useLocation();
  const user = usersData.find(user => user.username === username);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  if (!user) {
    return <NotFound />;
  }

  const renderContactLink = (link, label, icon) => (
    <div>
      <a href={link} className='icon-text' target="_blank" rel="noopener noreferrer">
        <div className='icon-circle'>{icon}</div>
        <div>{label}</div>
      </a>
    </div>
  );

  const generateVCardData = () => {
    const vCardData = `BEGIN:VCARD
VERSION:3.0
N:${user.name}
FN:${user.name}
TITLE:${user.position}
ORG:${user.company}
TEL;TYPE=CELL,VOICE:${user.mobile}
EMAIL;TYPE=WORK,PREF:${user.email}
ADR;TYPE=WORK:${user.address}
URL;TYPE=WORK:${user.url}
END:VCARD`;

    return vCardData;
  };

  const generateVCard = () => {
    const vCardData = generateVCardData();
    const blob = new Blob([vCardData], { type: 'text/vcard;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${user.username}.vcf`);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const share = () => {
    toggleModal();
  };

  const currentUrl = `${window.location.origin}${location.pathname}`;

  return (
    <div className='profile'>
      
      <Helmet>
        <title>{`${user.name}'s Contact Card`}</title>
        <meta name="description" content={`${user.name}, ${user.position} at ${user.company}.`} />
      </Helmet>

      <div className='qrtac-logo'>
        <img src={qrtacLogo} alt="Qrtac Logo" />
      </div>
      <div className='profile-detail'>
        {user.avatar && (
          <div className='profile-avatar'>
            <img src={user.avatar} alt={`${user.name}'s avatar`} />
          </div>
        )}
        <div className='profile-header'>
          {user.name && <div className='profile-name'>{user.name}</div>}
          {user.position && <div className='profile-position'>{user.position}</div>}
        </div>
        <div className='profile-contact-bar'>
          {user.mobile && renderContactLink(`tel:${user.mobile}`, 'CALL', <io5.IoCall />)}
          {user.mobile && renderContactLink(`https://wa.me/${user.mobile}`, 'WHATSAPP', <io5.IoLogoWhatsapp />)}
          {user.email && renderContactLink(`mailto:${user.email}`, 'EMAIL', <io5.IoMailOutline />)}
          {user.address && renderContactLink(`https://www.google.com/maps/search/${user.address}`, 'MAP', <io5.IoLocation />)}
        </div>
        <div className='profile-content'>
          {user.company && <div><div>Company</div><div>{user.company}</div></div>}
          {user.mobile && <div><div>Mobile</div><div>{user.mobile}</div></div>}
          {user.email && <div><div>Email</div><div>{user.email}</div></div>}
          {user.url && <div><div>URL</div><div><a href={user.url} target="_blank" rel="noopener noreferrer">{user.url}</a></div></div>}
          {user.address && <div><div>Address</div><div>{user.address}</div></div>}
        </div>
        <div className='profile-share-bar'>
          <div>
            <button className='icon-text' onClick={generateVCard}>
              <div className='icon-circle'><md.MdOutlinePermContactCalendar /></div>
              <div>VCARD</div>
            </button>
          </div>
          <div>
            <button className='icon-text' onClick={share}>
              <div className='icon-circle'><io5.IoQrCode /></div>
              <div>SHARE</div>
            </button>
          </div>
          {/* Modal for QR code */}
          <Modal
            className="modal-share"
            isOpen={showModal}
            onRequestClose={toggleModal}
            contentLabel="Share QR Code"
          >
            <div>
              <h2>SHARE</h2>
              <div><QRCode size={240} value={generateVCardData()}/></div>
              <div className='share-bottom-bar'>
                <div>
                  <a href={`https://www.facebook.com/sharer.php?u=${currentUrl}`}>
                    <div className='icon-circle'><io5.IoLogoFacebook size={48} /></div>
                  </a>
                </div>
                <div>
                  <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}`}>
                    <div className='icon-circle'><io5.IoLogoLinkedin size={48} /></div>
                  </a>
                </div>
                <div>
                  <a href={`https://twitter.com/share?url=${currentUrl}`}>
                    <div className='icon-circle'><io5.IoLogoTwitter size={48} /></div>
                  </a>
                </div>
                <div>
                  <a href={`whatsapp://send?text=${currentUrl}`}>
                    <div className='icon-circle'><io5.IoLogoWhatsapp size={48} /></div>
                  </a>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      {user.bio && user.bio.length > 0 && (
        <div className='profile-bio'>
          {user.bio.map((section, index) => (
            <p key={index} className={section.class || ''}>{section.text}</p>
          ))}
        </div>
      )}
    </div>
  );
}

export default UserProfile;
