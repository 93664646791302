import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom'; // Importing useParams from react-router-dom
import Home from './components/Home';
import UserProfile from './components/UserProfile';
import NotFound from './components/NotFound';
import usersData from './data/users.json';


function App() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Load users data from users.json file
    setUsers(usersData);
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/:username" element={<UserProfileWithCheck users={users} />} />
        <Route path="*" element={<NotFound />} /> {/* Use "*" to match any path not defined */}
      </Routes>
    </Router>
  );
}

// UserProfileWithCheck component to check if the user exists
function UserProfileWithCheck({ users }) {
  const { username } = useParams();

  // Find the user in the users array
  const user = users.find(user => user.username === username);

  // Render UserProfile if user exists, otherwise render NotFound
  return user ? <UserProfile user={user} /> : <NotFound />;
}

export default App;
