import React from 'react';
import * as go from 'react-icons/go';
import './NotFound.css';
import qrtacLogo from '../assets/logo-white.svg';

function NotFound() {
  return (
    <div className='error'>
      <div className='error-message'>
        <div><go.GoAlert size={120} /></div>
        <h1>Sorry, Something is missing</h1>
        <p>The page you're attempting to reach may have been removed, or there could be an error in the link you entered, or the individuals you're looking for are no longer part of our organization.</p>
      </div>
      <div className='error-footer'>
          <img src={qrtacLogo} alt="Qrtac Logo" />
        </div>
    </div>
  );
}

export default NotFound;
