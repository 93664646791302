// components/Home.js
import React from 'react';
import * as io5 from 'react-icons/io5';
import './Home.css';
import qrtacLogo from '../assets/logo-white.svg';

function Home() {
  return (
    <div className='home'>
      <div>
        <div><io5.IoIdCardOutline className='icon' /></div>
        <div><img src={qrtacLogo} alt="Qrtac Logo" /></div>
      </div>
    </div>
  );
}

export default Home;
